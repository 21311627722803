import ProductHit from 'components/organisms/Header/InstaSearch/ProductHit';
import React from 'react';
import { useHits } from 'react-instantsearch';

const TopBannerHits = React.forwardRef((_, ref) => {
  const { items } = useHits();
  return (
    <div className="hero-search" ref={ref} onClick={e => e.stopPropagation()}>
      {items?.length > 0 ? (
        items.map(hit => <ProductHit hit={hit} />)
      ) : (
        <div className="hero-search__no-results">
          <p>No results found</p>
        </div>
      )}
    </div>
  );
});

export default TopBannerHits;
